<template>
    <div class="form-container">
      <div class="header-container">
        <img src="@/assets/email-icon.png" alt="Correo Icono" class="header-icon">
        <h1>Restablecer Cuentas</h1>
      </div>
      
      <form @submit.prevent="consultarCorreo" class="form-content">
        <div class="form-group">
          <label for="plataforma">Plataforma:</label>
          <div class="select-container">
            <select v-model="plataforma" required>
              <option disabled value="">Seleccione una plataforma</option>
              <option value="NETFLIX">NETFLIX</option>
              <option value="AMAZON PRIME">AMAZON PRIME</option>
              <option value="DISNEY PLUS">DISNEY PLUS</option>
            </select>
            <div class="logo-container">
              <img v-if="plataforma === 'NETFLIX'" src="@/assets/netflix-logo.png" alt="NETFLIX" class="logo">
              <img v-if="plataforma === 'AMAZON PRIME'" src="@/assets/amazon-logo.png" alt="AMAZON PRIME" class="logo">
              <img v-if="plataforma === 'DISNEY PLUS'" src="@/assets/disney.png" alt="DISNEY PLUS" class="logo">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <label for="correo">Correo:</label>
          <input type="email" v-model="correo" required />
        </div>
  
        <div class="form-group">
          <label for="codigodistribuidor">Código Distribuidor:</label>
          <input type="text" v-model="codigodistribuidor" required />
        </div>
  
        <button type="submit">Consultar</button>
      </form>
  
      <!-- Mostrar el resultado en un div si hay resultados -->
        <div v-if="resultados.length > 0" class="response-container">
        <h2>Resultados de la Consulta:</h2>
        <p v-if="resultados.length > 1">Se encontraron {{ resultados.length }} correos:</p>
        <p v-else>Se encontró 1 correo:</p>

        <!-- Iteramos sobre cada correo -->
        <div v-for="(item, index) in resultados" :key="index" class="email-item">
            <h3>Correo {{ index + 1 }}</h3>
            <p><strong>Remitente:</strong> {{ item.remitente }}</p>
            <p><strong>Asunto:</strong> {{ item.asunto }}</p>
            <p><strong>Cuerpo del Mensaje:</strong></p>
            <div v-html="item.cuerpo" class="email-body"></div>
            <p><strong>Error:</strong> {{ item.error }}</p>
        </div>
        </div>

        <!-- Mostrar mensaje si no hay resultados -->
        <div v-if="resultados.length === 0 && !error && formSubmitted" class="response-container no-result-container">
        <h2>No hay resultados</h2>
        <p>No se encontraron correos para la consulta realizada.</p>
        </div>
  
      <!-- Mostrar error si ocurre -->
      <div v-if="error" class="response-container error-container">
        <h2>Error:</h2>
        <p>{{ error }}</p>
      </div>
    </div>
  </template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      plataforma: '',
      correo: '',
      codigodistribuidor: '',
      resultados: [],
      error: null,
      formSubmitted: false,
    };
  },
  methods: {
    async consultarCorreo() {
        this.resultados = [];
        this.error = null;
        this.formSubmitted = false;

      try {
        const response = await axios.post('http://portal.netplexcompany.com/gplataformas/consultarcorreoapi/', {
        //const response = await axios.post('http://localhost:8000/gplataformas/consultarcorreoapi/', {
          plataforma: this.plataforma,
          correo: this.correo,
          codigodistribuidor: this.codigodistribuidor
        });
        // Asume que la respuesta es una lista en object_list
        this.resultados = response.data.object_list.map(item => ({
          remitente: item.remitente || 'Desconocido',
          asunto: item.asunto || 'Sin asunto',
          cuerpo: item.cuerpo || 'Sin cuerpo de mensaje',
          error: item.error || 'Sin errores'
        }));
        this.formSubmitted = true; // Indicamos que se realizó una consulta

      } catch (error) {
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'Error en la solicitud, No es Distribuidor Autorizado';
        }
        this.formSubmitted = true; 
      }
    }
  }
}
</script>


<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 40px;
  background-color: #f4f4f4;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.header-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

h1 {
  font-size: 2em;
  color: #333;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.form-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input, select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

button:hover {
  background-color: #358a6d;
}

.select-container {
  display: flex;
  align-items: center;
}

.logo-container {
  margin-left: 10px;
}

.logo {
  width: 50px;
  height: auto;
}

.response-container {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  text-align: left;
  word-wrap: break-word;
}

.error-container {
  background-color: #f8d7da;
  color: #721c24;
}

.no-result-container {
  text-align: center;
  margin-top: 20px;
}

.no-result-container h2 {
  color: #ff6f61;
}
</style>