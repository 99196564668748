<template>
  <div id="app">
    <ConsultaCorreo />
  </div>
</template>

<script>
import ConsultaCorreo from './components/ConsultaCorreo.vue'

export default {
  components: {
    ConsultaCorreo
  }
}
</script>